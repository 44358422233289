import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

// import Home from "../themes/home";
// import Agricultura from "../themes/agricultura"; 
// import Pecuaria from "../themes/pecuaria";
// import Consultor from "../themes/consultor";
// import Sobre from "../themes/sobre";
import Suporte from "../themes/suporte";
import Termos from "../themes/termos";
// import CriarContaAgrare from "../themes/criarContaAgrare";
import Theme from "../themes/theme2";


export default class Routes extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Theme} />

            {/* <Route exact path="/agricultura" component={Agricultura} /> */}
            {/* <Route exact path="/pecuaria" component={Pecuaria} />
            <Route exact path="/consultor" component={Consultor} /> */}

            {/* <Route exact path="/sobre" component={Sobre} /> */}
            <Route exact path="/suporte" component={Suporte} />
            <Route exact path="/termos" component={Termos} />

            {/* <Route exact path="/register/:plan" component={CriarContaAgrare} /> */}

            {/* <Route exact path='/convenio-sicredi' component={() => {
              window.location.href = 'https://web.agrare.com.br/convenio-sicredi';
              return null;
            }} /> */}

            <Redirect to='/' />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
