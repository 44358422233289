import React from "react";
import { connect } from "react-redux";

class PromoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className={"promo-section ptb-100 " + (this.props.removeTopMargin ? '' : 'mt-5')}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="section-heading mb-5">
                  <span className="badge badge-primary badge-pill">
                    Software para Gestão de Fazendas
                  </span>
                  <h2 className="mt-4">O Melhor Investimento para Pequenos e Grandes Produtores!</h2>
                  <h5 className="h5 mt-3 mb-6">
                    Nossa missão é tornar as propriedades rurais MAIS EFICIENTES através da utilização de uma plataforma TECNOLÓGICA, SIMPLES e INTEGRADA ao planejamento produtivo.
                  </h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-6 mb-lg-0">
                <div className="card single-promo-card single-promo-hover">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="ti-cloud icon-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Acesso no Celular, Tablet ou Computador</h5>
                      <p className="text-muted mb-0">
                        Acesse de qualquer lugar, seja do campo ou do escritório, usando computador, tablet ou celular, conforme sua conveniência de horário.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="card single-promo-card single-promo-hover mb-lg-0">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="ti-mobile icon-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Aplicativo do Caderno de Campo Sem Internet</h5>
                      <p className="text-muted mb-0">
                        Nossos aplicativos funcionam nas plataformas Android e iPhone, permitindo o trabalho no caderno de campo sem acesso à Internet.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="card single-promo-card single-promo-hover mb-lg-0">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="ti-receipt icon-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Automação e Importação das NFe de Compras</h5>
                      <p className="text-muted mb-0">
                        Monitore todas as Inscrições da fazenda, recebendo automaticamente todas as NFe de Compra, facilitando operações de entrada no estoque e financeiro.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12 text-center" style={{ marginTop: '50px' }}>
                <a href="https://web.agrare.com.br/software-gestao-agricola" target="_blank" className="btn solid-btn">
                  Solicitar Demonstração GRÁTIS</a>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(PromoSection);
