module.exports = {
  home: {
    title: "Faça parte do seleto grupo de produtores que tornaram sua fazenda inteligente com a Agrare", // "A plataforma que vem auxiliando no crescimento de Fazendas Inteligentes",
    // title: "Torne sua fazenda inteligente", // "A plataforma que vem auxiliando no crescimento de Fazendas Inteligentes",
    description:
      // "Selecione a sua modalidade:",
      "Selecione abaixo o seu perfil:",
    items: [
      {
        title: "Sou Agricultor",
        description:
          "Tenha o controle das suas atividades agrícolas e a gestão financeira da sua propriedade rural.",
        icon: "img/tractor.png",
        route: "/agricultura"
      },
      {
        title: "Sou Pecuarista",
        description:
          "Tenha o controle do seu rebanho de gados e a gestão financeira da sua propriedade rural.",
        icon: "img/buffalo.png",
        route: "/pecuaria"
      },
      {
        title: "Ser um Parceiro",
        description:
          "Leve a inovação do agro para seus clientes, através de um aplicativo feito para o produtor rural.",
        icon: "img/handshake.png",
        route: "https://web.agrare.com.br/seja-um-parceiro-agrare"
      }
    ],
  },
  promo: {
    items: [
      {
        title: "Gestão Agrícola",
        description:
          "Tenha seu caderno de campo na palma da sua mão, gerencie a lavoura do plantio e manejo até a colheita.",
        icon: "ti-map-alt text-white"
      },
      {
        title: "Gestão Financeira",
        description:
          "Controle suas receitas e despesas e tenha o fluxo de caixa da sua fazenda em dia.",
        icon: "ti-stats-up text-white"
      },
      {
        title: "Gestão de Estoque",
        description:
          "Tenha o controle em dia do seu estoque e evite contra tempos em plena safra",
        icon: "ti-package text-white"
      },
      {
        title: "Trabalhe Sem Internet",
        description:
          "Realize as principais atividades do di-a-dia diretamente do campo, sem internet",
        icon: "ti-mobile text-white"
      }
    ]
  },
  support: {
    title: "Suporte Agrare",
    description:
      "Confira abaixo as opções para entrar em contato conosco",
    items: [
      {
        title: "Horário",
        description:
          "Segunda à Sexta-feira das 09h às 12h e das 13h30 às 18h (Horário de Brasília).",
        icon: "ti-timer text-white"
      },
      {
        title: "WhatsApp",
        description:
          "(55) 9 8467-0848",
        icon: "ti-comments text-white"
      },
      {
        title: "E-mail",
        description:
          "suporte@agrare.com.br",
        icon: "ti-email text-white"
      }
    ]
  },
  aboutUs: {
    title: {
      bold: "Use your android or ios device",
      normal: ""
    },
    description:
      "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
    items: [
      {
        title: "Clean Design",
        description:
          "Increase sales by showing true dynamics of your website",
        icon: "ti-vector text-white"
      },
      {
        title: "Secure Data",
        description:
          "Build your online store’s trust using Social Proof & Urgency",
        icon: "ti-lock text-white"
      },
      {
        title: "Retina Ready",
        description:
          "Realize importance of social proof in customer’s purchase decision",
        icon: "ti-eye text-white"
      }
    ]
  },
  price: {
    title: "Pricing Packages",
    description:
      "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
    packages: [
      {
        price: "$29",
        license: "Basic",
        isFeatured: false,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "2 months technical support",
          "2+ profitable keyword"
        ]
      },
      {
        price: "$50",
        license: "Popular",
        isFeatured: true,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "6 months technical support",
          "30+ profitable keyword"
        ]
      },
      {
        price: "$120",
        license: "Extended",
        isFeatured: false,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "1 year technical support",
          "70+ profitable keyword"
        ]
      }
    ]
  },
  testimonial: {
    comments: [
      {
        name: "Lorivan Formighieri",
        company: "Fazendas Guabijú, Primavera e São João (Alegrete/RS)",
        comment:
          "Há tempos procurava um aplicativo para auxiliar na gestão rural. Porém, os que encontrei eram complexos e exigiam bastante tempo para abastecer os dados. Além de apresentarem informações que eram desnecessárias para meu uso e demandavam tempo. Então procurei algo simples e funcional, que atendesse as minhas expectativas."
      },
      // {
      //   name: "Alexei França",
      //   company: "Serras de Minas (Belo Horizonte/MG)",
      //   comment:
      //     "O uso do sistema é bem intuitivo e está a cada dia aprimorando a forma de lançamentos. O principal benefício é que consigo organizar a parte de custos e receitas, colheita e manejo, ou seja, tudo que acontece na  propriedade. "
      // },
      {
        name: "Télio Moraes",
        company: "Fazenda Agropecuária 2S (Soledade/RS)",
        comment:
        "Os benefícios que o Agrare trouxe foi ter as informações que preciso e poder anotar estas em qualquer lugar e hora. Foi poder ver o resultado do trabalho em tempo real, podendo analisar o passado e planejar o futuro." 
      },
    ]
  },
  contact: {
    title: "Siga o @agrareoficial",
    description:
      "Saiba das novidades e receba dicas na nossas redes sociais.",
  },
  trustedCompany: [
    "img/client-1-color.png",
    "img/client-6-color.png",
    "img/client-3-color.png",
    "img/client-4-color.png",
    "img/client-5-color.png"
  ],
  trustedCompanyGray: [
    "img/client-1-gray.png",
    "img/client-2-gray.png",
    "img/client-3-gray.png",
    "img/client-4-gray.png",
    "img/client-5-gray.png"
  ],
  trustedCompanyAboutUs: {
    title: "Parceiros Agrare",
    description: "Conheça alguns de nossos parceiros.",
    companies: [
      // "img/agrare/parceiros/parceiro1.png",
      "img/agrare/parceiros/parceiro2.png",
      "img/agrare/parceiros/parceiro3.png",
      "img/agrare/parceiros/parceiro4.png",
      "img/agrare/parceiros/parceiro5.png",
      // "img/agrare/parceiros/parceiro6.png",
      // "img/agrare/parceiros/parceiro7.png"
    ]
  },
  teamMember: {
    members: [
      {
        name: "Edna Mason",
        designation: "Senior Designer",
        image: "img/team-4.jpg"
      },
      {
        name: "Arabella Ora",
        designation: "Senior Designer",
        image: "img/team-1.jpg"
      },
      {
        name: "John Charles",
        designation: "Senior Designer",
        image: "img/team-2.jpg"
      },
      {
        name: "Jeremy Jere",
        designation: "Senior Designer",
        image: "img/team-3.jpg"
      },
    ]
  },
  video: {
    link: "https://www.youtube.com/watch?v=9No-FiEInLA"
  }
};
