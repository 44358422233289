import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

class HeroSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };
    }

    handleShow = () => {
        this.setState({ showModal: true });
    };

    handleClose = () => {
        this.setState({ showModal: false });
    };

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>
                <section className="hero-section pt-100 background-img"
                    style={{
                        backgroundImage: "url('img/agrare/img-background-topo.jpeg')",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundSize: "cover"
                    }}>
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-6 col-lg-6">
                                <div className="hero-content-left text-white mt-5">
                                    <h1 className="text-white"><span>Impulsionamos</span> o Crescimento da Sua Fazenda com Dados</h1>
                                    <p className="lead">Gestão Simples e Funcional! Tenha controle do campo até o escritório com nossa plataforma de gestão de fazendas</p>

                                    <a href="https://web.agrare.com.br/software-gestao-agricola" target="_blank" className="btn google-play-btn">
                                        Solicitar Demonstração GRÁTIS</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="hero-animation-img" onClick={this.handleShow}>
                                    <img src="img/agrare/img-topo.png" alt="img" className="img-fluid" style={{ cursor: 'pointer' }} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <ul className="list-inline counter-wrap">
                                <li className="list-inline-item">
                                    <div className="single-counter text-center">
                                        <img src="img/agrare/graos.png" alt="img" height="80" />
                                        <h6 style={{ marginTop: '5px', fontWeight: 'bold' }}>Cultivo de Grãos</h6>
                                    </div>
                                </li>
                                <li className="list-inline-item">
                                    <div className="single-counter text-center">
                                        <img src="img/agrare/cafe.png" alt="img" height="80" />
                                        <h6 style={{ marginTop: '5px', fontWeight: 'bold' }}>Café</h6>
                                    </div>
                                </li>
                                <li className="list-inline-item">
                                    <div className="single-counter text-center">
                                        <img src="img/agrare/cana.png" alt="img" height="80" />
                                        <h6 style={{ marginTop: '5px', fontWeight: 'bold' }}>Cana-de-açúcar</h6>
                                    </div>
                                </li>
                                <li className="list-inline-item">
                                    <div className="single-counter text-center">
                                        <img src="img/agrare/horti.png" alt="img" height="80" />
                                        <h6 style={{ marginTop: '5px', fontWeight: 'bold' }}>Culturas Anuais</h6>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <Modal show={this.state.showModal} onHide={this.handleClose} size="lg" centered>
                        <Modal.Body>
                            <iframe width="100%" height="500" src="https://www.youtube.com/embed/UqYYSVpg-O8?autoplay=1&mute=1" title="Agrare Gestor Rural" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                        </Modal.Body>
                    </Modal>
                </section>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    state
}))(HeroSection);
