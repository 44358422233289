import React from "react";
import _data from "../../data";
import ReactGA from 'react-ga';

class Termos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            teamMember: {},
        };
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
        this.setState({
            teamMember: _data.teamMember,
        });

        ReactGA.event({
            category: 'Termos',
            action: 'Entrou na página de termos'
        });
    }

    render() {
        return (
            <React.Fragment>
                <section className="team-single-section ptb-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-sm-12 col-lg-12">
                                <div className="team-single-text">
                                    <div className="team-name mb-4">
                                        <h4 className="mb-1">Termos de Uso</h4>
                                        <span>Agrare Gestor Rural</span>
                                    </div>
                                    <div className="text-content mt-20">
                                        <p>
                                            A Agrare Desenvolvimento de Sistemas Ltda. (doravante apenas a “Agrare”)
                                            estabelece nestes Termos de Uso as condições para utilização do software Agrare,
                                            seja através do navegador e/ou dos aplicativos disponíveis. Antes de utilizar o
                                            software Agrare, é necessário que você leia, entenda e concorde com estes Termos
                                            de Uso e com nossa Política de Privacidade.
                    </p>

                                        <h4 className="mb-1">I. Declarações iniciais e aceite</h4>
                                        <p>
                                            1.1. Estes Termos de Uso constituem um Contrato de Licença de Usuário Final (End
                                            User License Agreement) (doravante apenas o “EULA”) entre o licenciado (doravante
                                            apenas o “Licenciado”) e a Agrare Desenvolvimento de Sistemas Ltda., pessoa
                                            jurídica de direito privado, inscrita no CNPJ sob n. 35.842.386/0001-63, com sede na
                                            BR 285, UPF Parque Módulo III, Sala 02, bairro São José, CEP 99.052-900, Passo
                                            Fundo - RS, Brasil (doravante apenas a “Agrare” ou a “Licenciante”) para uso do
                                            programa de computador denominado Agrare (doravante apenas o “Software”), de
                                            propriedade exclusiva da Licenciante, disponibilizado pela Licenciante por meio do
                                            website www.agrare.com.br (doravante apenas o “Site”) e dos aplicativos para
                                            iPhone, iPad e Android (doravante apenas os “Aplicativos”), conforme modalidade de
                                            contratação eleita pelo Licenciado, compreendendo o programa de computador e
                                            podendo incluir os meios físicos associados, bem como quaisquer materiais
                                            impressos e qualquer documentação online ou eletrônica.
                    </p>

                                        <p>
                                            1.2. ACEITE DOS TERMOS DE USO E DA POLÍTICA DE PRIVACIDADE. O
                                            Licenciado deve ler de forma atenta e minuciosa o presente EULA e a Política de
                                            Privacidade (doravante apenas a “Política de Privacidade”) que faz parte integrante e
                                            indissociável deste EULA para todo e qualquer efeito. Antes de utilizar o Software,
                                            seja através do Site ou dos Aplicativos, ainda que a título de teste, o Licenciado
                                            deverá expressar o seu aceite em relação às disposições deste EULA e da Política
                                            de Privacidade.
                    </p>

                                        <p>
                                            1.3. Ao utilizar o Software, seja através do Site ou dos Aplicativos, ainda que de forma
                                            parcial ou a título de teste, o Licenciado estará vinculado aos termos deste EULA,
                                            manifestando sua concordância integral com os termos e disposições deste EULA,
                                            especialmente, mas sem se limitar, com relação ao consentimento para o acesso,
                                            coleta, uso, armazenamento, tratamento e técnicas de proteção dos dados e
                                            informações do Licenciado pela Licenciante, necessárias para a integral execução
                                            das funcionalidades ofertadas pelo Software, conforme disposições da Política de
                                            Privacidade da Agrare. Em caso de discordância com qualquer disposição deste
                                            EULA e/ou da Política de Privacidade, o Licenciado deverá interromper a utilização
                                            do Software imediatamente.
                    </p>

                                        <p>
                                            1.4. O Licenciado declara ter lido, compreendido e aceito todos os termos e condições
                                            do presente EULA e da Política de Privacidade. Declara, ainda, ter pleno
                                            conhecimento dos seus direitos e obrigações decorrentes do presente EULA e da
                                            Política de Privacidade. Este EULA e a Política de Privacidade em anexo constituem
                                            o contrato definitivo entre o Licenciado e a Agrare em relação ao uso do Software,
                                            substituindo quaisquer outros contratos ou acordos prévios celebrados entre estas
                                            partes, sejam verbais ou escritos.
                    </p>

                                        <p>
                                            1.5. O Licenciado declara que não utilizará o Software para fins ilícitos, contrários ao
                                            estabelecido neste EULA e na Política de Privacidade, e/ou que possam provocar
                                            danos aos direitos e interesses da Agrare, de outros Licenciados e/ou de quaisquer
                                            terceiros, ou, ainda, que possam de qualquer forma danificar, inutilizar, sobrecarregar
                                            ou deteriorar o Software, o Site e/ou os Aplicativos ou impedir o normal uso ou
                                            desfrute dos serviços ali oferecidos.
                    </p>

                                        <p>
                                            1.6. O Licenciado declara estar ciente de que as operações que correspondam ao
                                            aceite do presente EULA e da Política de Privacidade, de determinadas condições e
                                            opções, bem como cancelamento, rescisão e alterações deste EULA, serão
                                            registradas nos bancos de dados da Agrare, juntamente com a data e hora em que
                                            foram realizadas pelo Licenciado, podendo tais informações ser utilizadas como prova
                                            pelas partes, independentemente do cumprimento de qualquer outra formalidade.
                    </p>

                                        <p>
                                            1.7. Os termos deste EULA e da Política de Privacidade aplicam-se ao Licenciado e
                                            a todos os usuários cadastrados pelo Licenciado para acesso e utilização do
                                            Software.
                    </p>

                                        <p>
                                            1.8. O Licenciado declara estar ciente de que (i) o tráfego de dados que lhe dá acesso
                                            ao Software é suportado por um serviço prestado pela operadora de serviços de
                                            Internet e telecomunicações escolhida e contratada pelo Licenciado e que (ii) tal
                                            contratação é completamente independente da Agrare e/ou do Software, não
                                            possuindo a Agrare qualquer responsabilidade relativa à impossibilidade de acesso
                                            ao Software decorrente de falha nos serviços de Internet e telecomunicações
                                            contratados pelo Licenciado.
                    </p>

                                        <p>
                                            1.9. O Licenciado declara estar ciente de que a Agrare poderá, a qualquer tempo e
                                            sem qualquer comunicação prévia, alterar unilateralmente o Software em qualquer
                                            aspecto substancial, como condições de operação, condições técnicas e de uso, a
                                            fim de aprimorar e/ou introduzir novas funcionalidades no Software, em benefício do
                                            Licenciado.
                    </p>

                                        <h4 className="mb-1">II. Definições</h4>
                                        <p>
                                            2.1. Para fins de interpretação deste EULA e da Política de Privacidade, ficam
                                            convencionadas que as definições utilizadas neste documento terão o significado
                                            abaixo descrito:
                    </p>

                                        <p>
                                            a. Aplicativo: a expressão “Aplicativo” significa os aplicativos da Agrare, disponíveis
                                            para iPhone, iPad e Android, por meio dos quais o Licenciado acessa e utiliza o
                                            Software.
                    </p>

                                        <p>
                                            b. Dados do Licenciado: a expressão “Dados do Licenciado” significa todo e qualquer
                                            dado ou informação inserida no Software pelo Licenciado e/ou pelos usuários por ele
                                            autorizados e cadastrados, incluindo, mas sem se limitar, os Dados Cadastrais,
                                            conforme detalhado na Política de Privacidade.
                    </p>

                                        <p>
                                            c. Dispositivos: a expressão “Dispositivos” (ou “Dispositivo”, quando individualmente
                                            considerado) significa os computadores, notebooks, netbooks, smartphones, tablets,
                                            e quaisquer outros equipamentos que se conectem à Internet.
                    </p>

                                        <p>
                                            d. Dados Cadastrais: a expressão “Dados Cadastrais” significa qualquer dado
                                            disponibilizado pelo Licenciado para fins de cadastro e que o identifique, tais como
                                            nome/razão social, CPF ou CNPJ, endereço, data de nascimento, número de telefone,
                                            endereço eletrônico, números de documentos, etc., conforme detalhado na Política
                                            de Privacidade.
                    </p>

                                        <p>
                                            e. Internet: a expressão “Internet” significa o sistema constituído do conjunto de
                                            protocolos lógicos, estruturado em escala mundial para uso público e irrestrito, com a
                                            finalidade de possibilitar a comunicação de dados entre Dispositivos por meio de
                                            diferentes redes.
                    </p>

                                        <p>
                                            f. Licenciados: a expressão “Licenciados” (ou “Licenciado”, quando individualmente
                                            considerado) significa as pessoas físicas ou jurídicas (devidamente constituídas e
                                            representadas), com plena capacidade de contratar, que acessarem o Software,
                                            através do Site ou Aplicativos, realizarem seus cadastros e aceitarem os termos do
                                            presente EULA e da Política de Privacidade, passando a usufruir das funcionalidades
                                            oferecidas, conforme modalidade de contratação eleita.
                    </p>

                                        <p>
                                            g. Site: a expressão “Site” significa o website www.agrare.com.br, por meio do qual o
                                            Licenciado acessa e utiliza o Software através do seu navegador web.
                    </p>

                                        <p>
                                            h. Software: a expressão “Software” significa o software (sistema de computador)
                                            denominado Agrare, de propriedade exclusiva da Licenciante, cujas funcionalidades
                                            e serviços são disponibilizados ao Licenciado, conforme modalidade de contratação
                                            eleita, através do Site e Aplicativos.
                    </p>

                                        <h4 className="mb-1">III. Licença de uso do software</h4>
                                        <p>
                                            3.1. O Software é um programa de computador, cujo acesso é disponibilizado através
                                            do Site e dos Aplicativos, destinado à gestão agrícola e financeira de propriedades
                                            rurais. A contratação do Software se dá através de Planos de escolha dos
                                            Licenciados, conforme especificado no Capítulo V a seguir.
                    </p>

                                        <p>
                                            3.2. Por meio deste EULA, a Agrare concede ao Licenciado uma licença revogável,
                                            não exclusiva e intransferível para uso do Software de propriedade da Agrare, nos
                                            termos e limites aqui estabelecidos.
                    </p>

                                        <p>
                                            3.3. O Licenciado não poderá utilizar e nem permitir que o Software seja utilizado para
                                            outra finalidade que não seja o processamento de suas informações e/ou de pessoas
                                            jurídicas indicadas pelo Licenciado no ato de realização do seu cadastro, exceto na
                                            hipótese de o Licenciado atuar como (i) uma empresa de Consultoria Agrícola, (ii) um
                                            órgão de fomento à pesquisa, ou (iii) uma organização diversa (associação, fundação,
                                            cooperativa, etc.) que utiliza o Software para processar informações de seus clientes
                                            / associados, observados os limites estabelecidos neste EULA.
                    </p>

                                        <p>
                                            3.3.1. Na hipótese excepcional de o Licenciado utilizar o Software para tratamento de
                                            dados ou informações de terceiros, conforme previsto na Cláusula 3.3 acima, o
                                            Licenciado compromete-se a tratar tais dados em conformidade com a legislação
                                            vigente, especialmente a Lei n. 13.709/18, e com o contrato celebrado com os
                                            terceiros.
                    </p>

                                        <p>
                                            3.4. A licença de uso do Software aqui concedida não implica na capacidade de
                                            acessar outros softwares além daqueles originalmente localizados no Software. Em
                                            nenhuma hipótese, o Licenciado terá acesso ao código fonte do Software ora
                                            licenciado, por este se tratar de propriedade intelectual da Licenciante.
                    </p>

                                        <h4 className="mb-1">IV. Propriedade intelectual</h4>
                                        <p>
                                            4.1. O Software ora licenciado, bem como todos os seus componentes e elementos,
                                            incluindo novas versões e atualizações, são de propriedade exclusiva da Agrare.
                                            Todo o conteúdo disponível no Site e/ou nos Aplicativos, assim como seus desenhos
                                            gráficos e seus códigos fonte, são protegidos por direito autoral (Leis ns. 9.609/98 e
                                            9.610/98) e são de propriedade exclusiva da Agrare. Para fins de interpretação desta
                                            cláusula, a expressão conteúdo (o “Conteúdo”) se entende por todas as
                                            funcionalidades e todo o material disponibilizado no Site e nos Aplicativos, incluindo
                                            desenhos, textos, gráficos, imagens, vídeos, informações, música, assim como a sua
                                            seleção e disposição.
                    </p>

                                        <p>
                                            4.2. O Licenciado não adquire, em nenhuma hipótese, qualquer direito sobre ou
                                            relacionado ao Software ou qualquer componente dele, além dos direitos
                                            expressamente licenciados ao Licenciado sob o presente EULA.
                    </p>

                                        <p>
                                            4.3. Caso o Licenciado venha a solicitar ou realizar quaisquer customizações
                                            específicas do Software, tais customizações serão consideradas como parte do
                                            Software aqui licenciado pela Agrare, ficando, portanto, sua propriedade incorporada
                                            pela Agrare e seu uso condicionado a estas cláusulas contratuais.
                    </p>

                                        <p>
                                            4.4. Caso o Licenciado venha a desenvolver um novo módulo ou produto que
                                            caracterize cópia, de todo ou em parte, quer seja do dicionário de dados quer seja do
                                            programa de computador, este desenvolvimento será considerado como parte do
                                            Software aqui licenciado pela Agrare, ficando, portanto, sua propriedade incorporada
                                            pela Agrare e seu uso condicionado a estas cláusulas contratuais.
                    </p>

                                        <p>
                                            4.5. O Licenciado não adquire, em nenhuma hipótese, por este EULA ou pela
                                            utilização do Software, qualquer direito de propriedade intelectual ou outros direitos
                                            exclusivos, incluindo patentes, desenhos, marcas, direitos autorais ou quaisquer
                                            direitos sobre informações confidenciais ou segredos de negócio, em relação ao
                                            Conteúdo disponibilizado no Site e/ou nos Aplicativos, incluindo, mas não se limitando
                                            a textos, gráficos, imagens, logotipos, ícones, fotografias, conteúdo editorial,
                                            notificações, softwares e qualquer outro material, sobre ou relacionados ao Software
                                            ou alguma parte dele.
                    </p>

                                        <p>
                                            4.6. É expressamente vedado ao Licenciado ou a terceiros, de forma geral:
                        <br />a. Copiar, ceder, sublicenciar, vender, dar em locação ou em garantia, reproduzir,
                        doar, alienar de qualquer forma, transferir total ou parcialmente, sob quaisquer
                        modalidades, gratuita ou onerosamente, provisória ou permanentemente, o Software,
                        assim como seus módulos, partes, manuais ou quaisquer informações a ele relativas;
                        <br />b. Retirar ou alterar, total ou parcialmente, os avisos de reserva de direito existentes
                        no Software e na sua documentação;
                        <br />c. Praticar engenharia reversa, descompilação ou desmontagem do Software;
                        <br />d. Copiar, distribuir, transmitir, publicar, ou de qualquer outra forma modificar o
                        Conteúdo do Site e dos Aplicativos.
                    </p>

                                        <p>
                                            4.7. Não poderão ser utilizadas as marcas, nome fantasia, denominações sociais,
                                            logotipos, slogans ou quaisquer outros sinais distintivos relacionados à Agrare e/ou
                                            de titularidade da Agrare, sem a prévia e expressa autorização da Agrare, na forma
                                            da legislação brasileira em vigor.
                    </p>

                                        <p>
                                            4.8. Qualquer violação do disposto neste capítulo constituirá infração aos direitos de
                                            propriedade intelectual da Agrare e sujeitará o Licenciado ou o terceiro infrator às
                                            sanções administrativas, civis e criminais aplicáveis.
                    </p>

                                        <p>
                                            4.9. A Agrare reserva para si todos os direitos não expressamente licenciados neste EULA.
                    </p>

                                        <h4 className="mb-1">V. Cadastro do licenciado</h4>
                                        <p>
                                            5.1. Ao solicitar ou efetuar seu cadastro, o Licenciado declara, expressamente, para
                                            todos os fins de direito, possuir capacidade jurídica para a celebração desta relação
                                            contratual, bem como que é financeiramente responsável pela contratação dos
                                            serviços objeto destes Termos de Uso.
                    </p>

                                        <p>
                                            5.2. O Licenciado receberá um domínio, login e senha para acesso e uso do Software,
                                            devendo responsabilizar-se integralmente por todos os atos praticados com uso de
                                            seu domínio, login e senha, ainda que praticados por quaisquer terceiros, sejam eles
                                            usuários autorizados pelo Licenciado ou não.
                    </p>

                                        <p>
                                            5.3. Ao solicitar ou efetuar o cadastro, o Licenciado deverá fornecer os Dados
                                            Cadastrais solicitados para identificação, cadastro e uso do Software, conforme
                                            especificado na Política de Privacidade. O Licenciado deverá fornecer informações
                                            verdadeiras, atualizadas e completas, bem como comunicar à Agrare quaisquer
                                            alterações em seus Dados Cadastrais, inclusive mudança de endereço convencional
                                            ou eletrônico (e-mail).
                    </p>

                                        <p>
                                            5.3.1. Caso os dados informados pelo Licenciado no momento do cadastro (i) estejam
                                            incorretos ou incompletos, (ii) sofram alteração posterior não comunicada à Agrare,
                                            ou (iii) impossibilitem a comprovação e identificação adequada do Licenciado, a
                                            Agrare se reserva o direito de, a seu exclusivo critério, suspender ou cancelar
                                            automaticamente e independentemente de comunicação prévia, o acesso do
                                            Licenciado ao Software, sem que recaia ao Licenciado qualquer direito de
                                            indenização ou ressarcimento.
                    </p>

                                        <p>
                                            5.4. É facultado ao Licenciado cadastrar usuários secundários (os “Usuários
                                            Secundários”) para acesso e utilização do Sistema. Esses Usuários Secundários
                                            receberão login e senha específicos e deverão conferir seu aceite integral e específico
                                            a este EULA. Ao utilizar o Software, os Usuários Secundários declaram e reconhecem
                                            que estão cientes e concordam com todos os termos do presente EULA.
                    </p>

                                        <p>
                                            5.4.1. O cadastro dos Usuários Secundários está vinculado ao cadastro do
                                            Licenciado, que sempre será o cadastro principal. O Licenciado poderá, a qualquer
                                            tempo, criar novos cadastros de Usuários Secundários, bem como excluir tais
                                            cadastros, devendo responsabilizar-se por todos os atos praticados pelos Usuários
                                            Secundários relativos ao uso do Software.
                    </p>

                                        <p>
                                            5.5. O Licenciado compromete-se a comunicar imediatamente à Agrare sobre
                                            qualquer extravio, roubo, furto, perda ou uso não autorizado de seu login e/ou senha,
                                            a fim de a Agrare possa providenciar o seu bloqueio.
                    </p>

                                        <p>
                                            5.6. A Agrare não assume qualquer responsabilidade decorrente do uso indevido,
                                            negligente ou imprudente do login e/ou da senha do Licenciado.
                    </p>

                                        <h4 className="mb-1">VI. Preços, planos e condições de pagamento</h4>
                                        <p>
                                            6.1. O Licenciado deverá pagar à Agrare o valor do plano de licenciamento escolhido,
                                            de acordo com a periodicidade e a forma de pagamento definidas entre as opções de
                                            pagamento disponibilizadas ao Licenciado no ato da contratação. O valor dos planos
                                            de licenciamento varia conforme a quantidade de módulos e funcionalidades do
                                            Software disponibilizados ao Licenciado, e de acordo com a área da propriedade que
                                            será gerida por meio do Software (em hectares). Os planos e valores vigentes podem
                                            ser consultados no Site www.agrare.com.br.
                    </p>

                                        <p>
                                            6.1.1. Os valores dos planos de licenciamento estabelecidos no ato da contratação
                                            pelo Licenciado serão atualizados em periodicidade anual pela variação positiva do
                                            IGP-M (FGV) ou, no caso de extinção deste, por outro índice oficial que venha a
                                            substituí-lo.
                    </p>

                                        <p>
                                            6.2. O atraso ou a falta de pagamento, pelo Licenciado, de quaisquer valores devidos
                                            à Agrare nas suas respectivas datas de vencimento, ensejará a cobrança de multa de
                                            2% (dois por cento) sobre o valor devido, mais juros de mora de 1% (um por cento)
                                            ao mês, pro rata die, e correção monetária (IGP-M FGV), desde o vencimento até a
                                            data do efetivo pagamento. Sendo necessária a intervenção de advogado para fins
                                            de cobrança dos valores devidos, na via judicial ou administrativa, serão cobrados,
                                            ainda, honorários de 10% (dez por cento) sobre o valor devido.
                    </p>

                                        <p>
                                            6.3. O atraso ou a falta de pagamento, pelo Licenciado, de quaisquer valores devidos
                                            à Agrare nas suas respectivas datas de vencimento, por até 30 (trinta) dias, não
                                            acarretará a imediata e automática rescisão deste EULA, mas causará a imediata
                                            suspensão e bloqueio do acesso do Licenciado ao Software até que as
                                            pendências financeiras tenham sido regularizadas. O acesso ao Software
                                            somente será restabelecido após a identificação pela Agrare do pagamento integral
                                            de todos os valores devidos enquanto o acesso esteve suspenso. A identificação
                                            poderá ocorrer em até 2 (dois) dias úteis após a data de pagamento pelo Licenciado.
                    </p>

                                        <p>
                                            6.4. O atraso ou falta de pagamento, pelo Licenciado, de quaisquer valores devidos
                                            à Agrare nas suas respectivas datas de vencimento, por período superior a 30
                                            (trinta) dias, causará o cancelamento definitivo e bloqueio do acesso do
                                            Licenciado ao Software, podendo acarretar, ainda, a exclusivo critério da
                                            Agrare, a rescisão automática deste EULA. Nesta hipótese, caso o Licenciado
                                            tenha interesse em voltar a utilizar o Software, deverá pagar todos os valores em
                                            aberto e solicitar a reativação do seu cadastro e liberação do seu acesso junto à
                                            Agrare, sendo aplicável a tabela de preços vigente no momento da reativação do
                                            Plano.
                    </p>

                                        <p>
                                            6.5. É facultado ao Licenciado alterar o plano de licenciamento escolhido, desde que
                                            respeitados os prazos mínimos determinados pela Agrare para solicitação da
                                            alteração bem como para efetivação da alteração de plano solicitada. A alteração
                                            poderá ser relacionada (i) ao tamanho da área gerenciada pelo Software (aumento ou
                                            diminuição da área gerenciada) ou (ii) à upgrade ou downgrade do plano de
                                            licenciamento escolhido, conforme as modalidades de planos oferecidas pela Agrare.
                                            Em caso de alteração do plano de licenciamento, os valores devidos pelo Licenciado
                                            serão alterados de acordo com o respectivo plano escolhido.
                    </p>

                                        <p>
                                            6.5.1. Em caso de alteração do plano de licenciamento para fins de (i) diminuição da
                                            área de abrangência, ou (ii) downgrade de plano, os Dados do Licenciado relativos à
                                            (i) área retirada do plano, ou (ii) serviços e/ou módulos não contemplados no novo
                                            plano de licenciamento reduzido, serão disponibilizados ao Licenciado para
                                            exportação, na forma do Capítulo XIV.
                    </p>

                                        <p>
                                            6.6. A Agrare poderá, mediante prévia comunicação ao endereço eletrônico indicado
                                            pelo Licenciado em seu cadastro ou por meio de aviso no Site e/ou Aplicativos, definir
                                            preços para a oferta de determinados conteúdos e/ou serviços, ainda que inicialmente
                                            tais conteúdos e/ou serviços tenham sido ofertados de forma gratuita, sendo a
                                            utilização destes, após a referida comunicação pela Agrare, considerada como
                                            expressa concordância do Licenciado com a cobrança dos preços definidos.
                    </p>

                                        <h4 className="mb-1">VII. Obrigações do licenciado</h4>
                                        <p>
                                            7.1. Constituem obrigações do Licenciado, sem prejuízos de outras obrigações
                                            previstas neste EULA:
                        <br />a. Manter pessoal treinado para a operação do Software e para todas as
                        comunicações necessárias com a Agrare;
                        <br />b. Fornecer à Agrare, sempre que necessário e/ou no caso de ocorrência de erros ou
                        problemas com o Software, todos os documentos, relatórios e demais informações
                        que relatem as circunstâncias em que os erros / problemas ocorreram, objetivando
                        facilitar e agilizar os trabalhos de solução pela Agrare;
                        <br />c. Manter, às suas expensas, linha de telecomunicação, modem, software de
                        comunicação, Internet, endereço de correio eletrônico e outros recursos necessários
                        à comunicação com a Agrare;
                        <br />d. Efetuar os pagamentos relativos ao plano de licenciamento escolhido nas
                        respectivas datas de vencimento;
                        <br />e. Responsabilizar-se integralmente por todos os Dados do Licenciado inseridos no
                        Software, bem como pelo cadastramento, permissões, senhas e modo de utilização
                        de seus usuários autorizados;
                        <br />f. Certificar-se de que não está proibido por determinação legal e/ou contratual de
                        fornecer os Dados Cadastrais e quaisquer outros dados à Agrare, necessários para a
                        execução dos serviços oferecidos por este EULA;
                        <br />g. Não utilizar o Software de qualquer forma que possa implicar em ato ilícito, infração,
                        violação de direitos ou danos à Agrare ou a terceiros, incluindo, mas não se limitando
                        ao uso para invasão de dispositivo informático com o objetivo de obter, adulterar ou
                        destruir dados ou informações sem a autorização expressa do titular de tais dados ou
                        do dispositivo ou servidor nos quais estes estejam armazenados;
                        <br />h. Não publicar, enviar ou transmitir qualquer arquivo que contenha vírus, worms,
                        cavalos de troia ou qualquer outro programa que possa contaminar, destruir ou
                        interferir no bom funcionamento do Software;
                        <br />i. Informar a Agrare sempre que houver qualquer alteração dos Dados Cadastrais
                        fornecidos à Agrare;
                        <br />j. Comunicar imediatamente à Agrare qualquer suspeita de perda, furto, roubo,
                        extravio ou clonagem do seu login e senha de acesso ao Software, bem como realizar
                        a alteração da sua senha de forma imediata, por meio do Software, a bem de evitar
                        qualquer prejuízo aos seus Dados Cadastrais.
                    </p>

                                        <h4 className="mb-1">VIII. Obrigações da Agrare</h4>
                                        <p>
                                            8.1. Constituem obrigações da Agrare, sem prejuízos de outras obrigações previstas neste EULA:
                        <br />a. Assegurar o funcionamento regular do Software, desde que respeitadas as
                        condições de uso definidas na documentação e ressalvado o disposto no Capítulo XII;
                        <br />b. Corrigir eventuais falhas de programação (“bugs”) identificadas, podendo, a seu
                        critério, substituir as cópias dos Softwares com falhas por cópias corrigidas;
                        <br />c. Fornecer ao Licenciado, ato contínuo ao aceite deste EULA, acesso ao Software
                        durante a vigência deste EULA;
                        <br />d. Suspender o acesso ao Software ao Licenciado que esteja desrespeitando as
                        regras aqui estabelecidas ou as normas legais em vigor;
                        <br />e. Alterar as especificações e/ou características do Software licenciado para fins de
                        melhoria e/ou correção de erros;
                        <br />f. Disponibilizar serviços de suporte no período compreendido de segunda a sextafeira, das 09:00h às 12:00 e das 13:30 às 18:00h (pelo horário de Brasília), por
                        WhatsApp ((55) 9 8467-0848), e por meio de correio eletrônico
                        (suporte@agrare.com.br) para esclarecimento de dúvidas de ordem não técnica
                        diretamente relacionadas ao Software;
                        <br />g. Manter os Dados Cadastrais do Licenciado, bem como seus registros de acesso
                        em sigilo, sendo que tais informações serão armazenadas em ambiente seguro, em
                        observâncias aos termos da Política de Privacidade e da legislação aplicável.
                    </p>

                                        <h4 className="mb-1">IX. Níveis de serviço (SLA)</h4>
                                        <p>
                                            9.1. A Agrare empreenderá todos os esforços comercialmente razoáveis para manter
                                            o Software disponível, no mínimo, durante 99 % (noventa e nove por cento) do tempo
                                            de cada Ano de Serviço (doravante apenas o “Compromisso de Nível de Serviço”).
                                            Para fins de interpretação desta cláusula, “Ano de Serviço” será compreendido como
                                            os 365 (trezentos e sessenta e cinco) dias precedentes à data de uma reivindicação
                                            do Licenciado relacionada ao nível de serviço. Se o Licenciado estiver utilizando o
                                            Software há menos de 365 dias, o Ano de Serviço será ainda assim considerado como
                                            os 365 dias precedentes, sendo que os dias anteriores ao início da utilização do
                                            Software pelo Licenciado serão considerados como de 100% de disponibilidade.
                    </p>

                                        <p>
                                            9.2.Na hipótese de a Agrare não cumprir o Compromisso de Nível de Serviço aqui
                                            estabelecido, o Licenciado terá o direito de receber o crédito correspondente a 1 (um)
                                            mês da mensalidade do plano de licenciamento contratado ou 1/12 avos da
                                            anualidade do plano de licenciamento contratado (no caso de planos anuais)
                                            (doravante apenas o “Crédito de Serviço”).
                    </p>

                                        <p>
                                            9.3. Os períodos de inatividade operacional que ocorrerem antes de uma
                                            reivindicação bem-sucedida de Crédito de Serviço não poderão ser usados para efeito
                                            de reivindicações futuras.
                    </p>

                                        <p>
                                            9.4. O Compromisso de Nível de Serviço não se aplica caso as circunstâncias de
                                            indisponibilidade sejam causadas por: (i) uma interrupção do fornecimento de energia
                                            elétrica ou paradas emergenciais não superiores a 4 (quatro) horas ou que ocorram
                                            no período das 00:00 às 6:00h (horário de Brasília), (ii) fatores que fujam do razoável
                                            controle da Agrare, inclusive casos de caso fortuito, força maior ou de acesso à
                                            Internet e problemas correlatos; (iii) quaisquer atos ou omissões imputáveis ao
                                            Licenciado ou a terceiros, (iv) equipamento, software ou outras tecnologias que o
                                            Licenciado usar que impeçam o acesso regular do Software, (v) falhas de instâncias
                                            individuais não atribuíveis à indisponibilidade do Licenciado, (vi) práticas de
                                            gerenciamento da rede que possam afetar sua qualidade, (vii) problemas na
                                            sincronização dos Aplicativos por eventuais falhas do dispositivo.
                    </p>

                                        <h4 className="mb-1">X. Limitação e responsabilidades da Agrare</h4>
                                        <p>
                                            10.1. As obrigações e responsabilidades da Agrare são assumidas nos exatos termos
                                            e limites deste EULA. A Agrare não assume responsabilidade, sob nenhuma
                                            hipótese ou circunstância, por:
                        <br />a. Qualquer ato ou omissão praticado e/ou dano causado pelo Licenciado decorrente
                        do acesso ou uso do Software;
                        <br />b. Uso indevido do Software pelo Licenciado e/ou terceiros autorizados ou não pelo
                        Licenciado;
                        <br />c. Falhas, impossibilidades técnicas ou indisponibilidades do Software, ressalvado o
                        Compromisso de Nível de Serviço;
                        <br />d. Cumprimento dos prazos legais do Licenciado para entrega de documentos fiscais,
                        pagamentos de quaisquer tributos ou cumprimento de outras obrigações legais;
                        <br />e. Quaisquer decisões administrativas, gerenciais ou comerciais tomadas pelo
                        Licenciado com base nas informações fornecidas pelo Software, bem como pelos
                        danos e prejuízos eventualmente daí decorrentes;
                        <br />f. Eventuais problemas oriundos de ações de terceiros que possam interferir na
                        qualidade do serviço;
                        <br />g. Danos causados a terceiros em razão de conteúdo gerado pelo Licenciado através
                        de Software;
                        <br />h. Análise, revisão ou correção de quaisquer Dados do Licenciado inseridos pelo
                        Licenciado no Software, seja no que tange à precisão ou veracidade dos dados, seja
                        quanto à legalidade ou ameaça de violação em função do fornecimento destas
                        informações;
                        <br />i. Problemas oriundos de caso fortuito ou força maior (artigo 393, Lei 10.406/2002);
                        <br />j. Eventual instalação no(s) Dispositivo(s) do Licenciado ou de terceiros de vírus,
                        trojans, malwares, spywares ou quaisquer outros códigos, arquivos ou programas
                        informáticos análogos, desenhados para interromper, destruir ou limitar a
                        funcionalidade de qualquer software, hardware ou equipamento de
                        telecomunicações, que venham a ser criados, em decorrência da navegação na
                        Internet pelo Licenciado.
                    </p>

                                        <p>
                                            10.2. A Agrare jamais será responsável pelo conteúdo (Dados Cadastrais, Dados do
                                            Licenciado, ou quaisquer outros dados ou informações, senhas, cópias de
                                            informações, etc.) inseridos no Software. A Agrare não realiza qualquer análise,
                                            revisão ou correção de quaisquer informações inseridas pelo Licenciado no Software,
                                            sendo a responsabilidade por informações sempre e integralmente do Licenciado.
                    </p>

                                        <p>
                                            10.3. A Agrare jamais será responsável por danos de qualquer natureza, sejam eles
                                            diretos ou indiretos, materiais ou morais, relativos a perda de chance ou lucros
                                            cessantes, decorrentes ou relacionados ao uso ou indisponibilidade de uso do
                                            Software, por qualquer motivo. Sob nenhuma hipótese ou circunstância, a
                                            responsabilidade integral da Agrare com relação ao Licenciado por todo e qualquer
                                            dano eventualmente sofrido e comprovado excederá a quantia correspondente ao
                                            valor mensal do plano de licenciamento do Software pago pelo Licenciado à Agrare.
                    </p>

                                        <p>
                                            10.4. A Agrare adota as medidas de segurança adequadas de acordo com os padrões
                                            de mercado para a proteção das informações do Licenciado armazenadas no
                                            Software. Contudo, o Licenciado reconhece que nenhum sistema, servidor ou
                                            software está absolutamente imune a ataques e/ou invasões de hackers e outros
                                            agentes maliciosos, não sendo a Agrare responsável por qualquer exclusão,
                                            obtenção, utilização e/ou divulgação não autorizada de informações do Licenciado
                                            resultantes de ataques que a Agrare não poderia razoavelmente evitar por meio dos
                                            referidos padrões de segurança.
                    </p>

                                        <h4 className="mb-1">XI. Da retomada do software</h4>
                                        <p>
                                            11.1. A Agrare se reserva o direito de cancelar imediatamente o acesso do Licenciado
                                            ao Software nos casos em que o Licenciado usar o Software de forma diversa daquela
                                            estipulada no presente EULA e/ou na legislação aplicável.
                    </p>

                                        <h4 className="mb-1">XII. Da limitação de garantia</h4>
                                        <p>
                                            12.1. O Software aqui licenciado pela Agrare é fornecido "no estado em que se
                                            encontra" e "conforme a disponibilidade", com todas as falhas e sem garantia de
                                            qualquer espécie.
                    </p>

                                        <p>
                                            12.2. Não obstante o disposto na Cláusula 8.1 alínea ‘a’ acima, a Agrare não garante
                                            (i) que as funções contidas no Software atenderão às necessidades do Licenciado,
                                            (ii) que a operação do Software será ininterrupta ou livre de erros, (iii) que qualquer
                                            serviço atualmente oferecido no Software continuará disponível, (iv) que os defeitos
                                            no Software serão corrigidos ou (v) que o Software será compatível ou funcionará
                                            adequadamente quando combinado ou em conjunto com qualquer outro software,
                                            aplicações ou serviços de terceiros.
                    </p>

                                        <p>
                                            12.3. O Licenciado declara e reconhece que o Software não é adequado para ser
                                            utilizado e, por isso, não deve ser utilizado em situações ou ambientes nos quais a
                                            falha, o atraso, os erros ou inexatidões de conteúdo, dados ou informações fornecidas
                                            pelo Software possam levar à morte, danos pessoais, ou danos físicos ou ambientais
                                            graves.
                    </p>

                                        <h4 className="mb-1">XIII. Prazo</h4>
                                        <p>
                                            13.1. O presente EULA entra em vigor na data de seu aceite pelo Licenciado e
                                            vigorará pelo prazo do plano de licenciamento contratado pelo Licenciado. Este EULA
                                            será automaticamente renovado por iguais períodos, acompanhando as renovações
                                            do plano de licenciamento contratado, exceto se o Licenciado comunicar por escrito
                                            a Agrare do seu desinteresse na renovação do plano, através de um dos canais de
                                            contato especificado na Cláusula 14.1.
                    </p>

                                        <p>
                                            13.2. Este EULA poderá ser rescindido conforme nas hipóteses previstas no Capítulo
                                            XIV a seguir.
                    </p>

                                        <h4 className="mb-1">XIV. Cancelamento e rescisão</h4>
                                        <p>
                                            14.1. A rescisão deste EULA, pelo Licenciado, se dá através do pedido de
                                            cancelamento do plano de licenciamento contratado. O Licenciado poderá solicitar o
                                            cancelamento do seu plano e rescindir este EULA a qualquer tempo, desde que
                                            respeitada antecedência mínima de 30 (trinta) dias da data-base do plano de
                                            licenciamento, mediante envio de comunicação por escrito à Agrare, através do
                                            seguinte canal de comunicação: e-mail para cancelamento@agrare.com.br.
                                            Solicitações de cancelamento enviadas para outros canais de comunicação da
                                            Agrare, que não o expressamente mencionado nesta cláusula, não serão
                                            consideradas.
                    </p>

                                        <p>
                                            14.1.1. Para os planos de licenciamento com pagamento recorrente
                                            (mensal/trimestral/semestral/anual), caso, na data de solicitação do cancelamento ou
                                            comunicação de desinteresse na renovação do plano, pelo Licenciado, a fatura
                                            relativa à cobrança do período subsequente já tenha sido emitida e enviada pela
                                            Agrare, o Licenciado deverá efetuar o pagamento da referida fatura e demais taxas
                                            ou tributos incidentes.
                    </p>

                                        <p>
                                            14.1.2. Para os planos de licenciamento com pagamento antecipado, caso o
                                            Licenciado solicite o cancelamento do plano antes do término do prazo contratado,
                                            serão devidos os valores integrais relativos ao plano de licenciamento, e a Agrare não
                                            restituirá ao Licenciado quaisquer valores pagos relativos ao saldo restante do plano
                                            de licenciamento contratado e eventualmente não utilizado. Estes valores serão
                                            retidos pela Agrare a título de cláusula penal e ressarcimento de custos operacionais.
                    </p>

                                        <p>
                                            14.2. A Agrare, por sua vez, poderá rescindir este EULA a qualquer tempo, mediante
                                            envio de comunicação por escrito ao Licenciado, com aviso prévio mínimo de 15
                                            (quinze) dias, através de um dos seguintes canais de comunicação: e-mail principal
                                            do Licenciado, informado no seu cadastro de cobrança.
                    </p>

                                        <p>
                                            14.2.1. Caso, no momento da rescisão deste EULA por iniciativa da Agrare, o
                                            Licenciado tenha efetuado pagamento antecipado de valores relativos ao plano de
                                            licenciamento contratado, a Agrare restituirá ao Licenciamento os valores relativos ao
                                            saldo restante do plano de licenciamento contratado e não utilizado.
                    </p>

                                        <p>
                                            14.2.2. O prazo de aviso prévio estabelecido na Cláusula 14.2 e a obrigação de
                                            restituição de valores prevista na Cláusula 14.2.1 não se aplicam nas hipóteses da
                                            Cláusula 14.3 a seguir.
                    </p>

                                        <p>
                                            14.3. A Agrare poderá, ainda, rescindir este EULA, a qualquer tempo, sem
                                            necessidade de aviso prévio e/ou de restituição de quaisquer valores, nas seguintes
                                            hipóteses:
                        <br />a. Retomada do Software (Cláusula 11.1);
                        <br />b. Inadimplemento de valores, pelo Licenciado, por período superior a 30 dias
                        (Cláusula 6.4); e
                        <br />c. Violação dos termos e condições deste EULA pelo Licenciado.
                    </p>

                                        <p>
                                            14.4. Em quaisquer das hipóteses de rescisão deste EULA, o acesso do Licenciado
                                            ao Software será bloqueado a partir da data da rescisão (exceto nos casos de
                                            inadimplemento de valores, nos quais o bloqueio de acesso poderá ser imediato, na
                                            forma da Cláusula 6.3). Os Dados do Licenciado serão disponibilizados ao Licenciado
                                            para exportação (no formato .sql) dentro do prazo de 60 (sessenta) dias a contar da
                                            data da rescisão, conforme disposições do Capítulo XIV a seguir, sendo
                                            permanentemente excluídos após esse período.
                    </p>

                                        <p>
                                            14.5. As disposições relativas à propriedade intelectual (Capítulo IV) sobrevivem ao
                                            término deste EULA, por tempo indeterminado.
                    </p>

                                        <h4 className="mb-1">XV. Restituição de dados do licenciado</h4>
                                        <p>
                                            15.1. Uma vez bloqueado o acesso do Licenciado ao Software, a Agrare manterá os
                                            Dados do Licenciado armazenados no Software e disponíveis para exportação, pelo
                                            Licenciado, pelo período máximo de 60 (sessenta) dias a contar da data da rescisão
                                            e bloqueio de acesso. Durante este período, a Agrare tornará os Dados do Licenciado
                                            disponíveis para serem extraídos do Software em formato “.sql”. O procedimento de
                                            restituição de dados do Licenciado está detalhado na Política de Privacidade.
                    </p>

                                        <h4 className="mb-1">XVI. Disposições gerais e complementares</h4>
                                        <p>
                                            16.1. Eventuais lacunas e/ou contradições deste EULA deverão ser interpretadas de
                                            acordo com a vontade das partes e de acordo com a boa-fé contratual objetiva, na
                                            forma da legislação aplicável.
                    </p>

                                        <p>
                                            16.2. No caso de eventual declaração judicial de nulidade total ou parcial de quaisquer
                                            disposições deste EULA, deverão ser mantidas as demais disposições deste EULA
                                            que não forem objeto da nulidade, mediante preenchimento e integração das regras
                                            operacionais afetadas, pelos princípios da razoabilidade e da boa-fé.
                    </p>

                                        <p>
                                            16.3. A omissão ou a tolerância da Agrare diante de faltas cometidas pelo Licenciado
                                            e/ou pelos usuários por ele autorizados não implica em renúncia de direitos, em
                                            modificação, em novação ou, de qualquer forma, em fonte de direitos não escritos em
                                            favor do Licenciado, podendo a Agrare, a qualquer tempo, exigir do Licenciado o
                                            cumprimento da obrigação.
                    </p>

                                        <p>
                                            16.4. A licença não exclusiva para uso de Software objeto deste EULA, seus direitos
                                            e obrigações são concedidos ao Licenciado nos termos e limites deste EULA, sendo
                                            vedado ao Licenciado ceder ou transferir, a qualquer título, os direitos e obrigações
                                            acordados neste EULA. Tal limitação, no entanto, não atinge a Agrare, que poderá, a
                                            qualquer tempo, ceder, no todo ou em parte, os direitos e obrigações inerentes ao
                                            presente EULA.
                    </p>

                                        <p>
                                            16.5. O Licenciado concorda que a Agrare possa divulgar a celebração deste EULA
                                            para fins comerciais, fazendo menção ao nome/ razão social e à marca do Licenciado
                                            em campanhas comerciais, podendo, inclusive, divulgar mensagens enviadas de
                                            forma escrita ou oral, por telefone ou por qualquer outro meio de comunicação, para
                                            uso em sites, jornais, revistas e outras campanhas, enquanto vigorar o presente
                                            EULA. O Licenciado aceita, ainda, receber comunicações via correio eletrônico sobre
                                            treinamentos, parcerias e campanhas relacionadas ao Software.
                    </p>

                                        <p>
                                            16.6. Toda e qualquer comunicação escrita dirigida à Agrare relativamente a
                                            descumprimentos de obrigações contratuais ou de disposições destes EULA deverá
                                            ser encaminhada por e-mail para o endereço eletrônico ouvidoria@agrare.com.br.
                    </p>

                                        <h4 className="mb-1">XVII. Alterações do acesso, dos EULA e da política de privacidade</h4>
                                        <p>
                                            17.1. A Agrare, a qualquer tempo, a seu exclusivo critério e sem necessidade de
                                            qualquer aviso prévio ou posterior a qualquer Licenciado ou a terceiros, poderá:
                        <br />a. Suspender, cancelar, interromper ou alterar, total ou parcialmente, o acesso do
                        Licenciado ao Software, quando referido acesso ou seu cadastro estiverem em
                        violação das condições estabelecidas neste EULA;
                        <br />b. Excluir, total ou parcialmente, as informações cadastradas pelo Licenciado que não
                        estejam em consonância com as disposições deste EULA;
                        <br />c. Remover, alterar e/ou atualizar no todo ou em parte o Site e/ou os Aplicativos, bem
                        como o Conteúdo oferecido no Site e/ou Aplicativos;
                    </p>

                                        <p>
                                            17.2. A Agrare poderá, ainda, a qualquer tempo, alterar quaisquer termos e condições
                                            deste EULA e da Política de Privacidade, mediante comunicação ao Licenciado com
                                            aviso prévio mínimo de 15 (quinze) dias. A comunicação ao Licenciado será realizada
                                            ao endereço eletrônico informado no cadastro do Licenciado ou por qualquer outro
                                            meio que assegure que o Licenciado poderá tomar conhecimento dos novos termos
                                            e condições deste EULA e/ou da Política de Privacidade.
                    </p>

                                        <p>
                                            17.3. Qualquer alteração e/ou atualização destes EULA e/ou da Política de
                                            Privacidade em anexo passará a vigorar 15 (quinze) dias após a sua publicação no
                                            Site e/ou Aplicativos e comunicação aos Licenciados, feita na forma da Cláusula 17.2
                                            acima, e deverá ser integralmente observada pelo Licenciado.
                    </p>

                                        <p>
                                            17.4. A Agrare poderá, ainda, a seu exclusivo critério, suspender, modificar ou
                                            encerrar as atividades do Software, mediante comunicação prévia por escrito ao
                                            Licenciado, com antecedência mínima de 15 (quinze) dias, disponibilizando formas e
                                            alternativas de extrair ou exportar do Software os Dados do Licenciado, salvo nas
                                            hipóteses de caso fortuito ou força maior.
                    </p>

                                        <h4 className="mb-1">XVIII. Legislação e foro</h4>
                                        <p>
                                            18.1. Este EULA e a Política de Privacidade serão regidos, interpretados e sujeitarse-ão à legislação brasileira.
                    </p>

                                        <p>
                                            18.2. Quaisquer disputas ou controvérsias oriundas da relação entre a Agrare e o
                                            Licenciado, inclusive com relação ao descumprimento deste EULA e da Política de
                                            Privacidade ou pela violação dos direitos da Agrare, de outros Licenciados e/ou de
                                            terceiros, inclusive direitos de propriedade intelectual, de sigilo e de personalidade,
                                            serão processadas no Foro da cidade de Passo Fundo, Estado do Rio Grande do Sul,
                                            com exclusão de qualquer outro, por mais privilegiado que seja.
                    </p>

                                        <h5 className="mb-1">Passo Fundo/RS, 01 de janeiro de 2021.</h5>
                                        <h5 className="mb-1">Agrare Desenvolvimento de Sistemas Ltda.</h5>
                                        <h5 className="mb-1">Alex Becker - CEO &amp; Founder</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Termos;
